import {
  Grid,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useFacilityEHRsQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../../shared/EditButton";
import DeleteButton from "../../../../../shared/DeleteButton";
import { BaseButton } from "components/shared/BaseButton";

export default function CommunityEHRs() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const [ehrType, setEHRType] = useState(0);

  const {
    data: ehrs,
    isLoading,
    isSuccess,
    isError,
  } = useFacilityEHRsQuery(id, { skip: !id });

  useEffect(() => {
    if (isSuccess && ehrs) {
      setEHRType(ehrs?.ehrType);
    }
  }, [isSuccess]);

  const eHRColumns = [
    {
      field: "ehridentifier",
      headerName: "Identifier",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "alternateEhridentifier",
      headerName: "Alt Identifier",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addcommunityehr?id=${id}&ehrId=${params.row.id}`}
              data={ehrs?.ehRs}
              title="Community EHR Identifier"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.ehridentifier}
              apiPath="deleteFacilityEHR"
            />
            {ehrType > 0 ? (
              <OptionsButton
                index={params.row.id}
                facilityId={params.row.facilityId}
                facilityName={params.row.facilityName}
                ehridentifier={params.row.ehridentifier}
                alternateEhridentifier={params.row.alternateEhridentifier}
                ehrType={ehrType}
              />
            ) : undefined}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        marginBottom={"8px"}>
        <BaseButton
          marginLeft={"0px !important"}
          text={"Add EHR Identifier"}
          endIcon={"playlist_add"}
          To={`addcommunityehr?id=${id}`}
        />
        <Grid
          display={"flex"}
          alignContent={"center"}
          justifyContent={{ xs: "space-between", sm: "end" }}
          marginTop={{ xs: "10px", sm: "0px" }}>
          <BaseButton
            marginLeft={"0px !important"}
            text={"EHR Diet Mapping"}
            endIcon={"playlist_add"}
            To={`ehrdietmapping?id=${id}`}
          />
          <BaseButton
            text={"EHR Liquid Mapping"}
            endIcon={"playlist_add"}
            To={`ehrliquidmapping?id=${id}`}
          />
        </Grid>
      </Grid>

      <BaseDataGrid
        rows={ehrs?.ehRs}
        columns={eHRColumns}
        error={isError}
        loading={isLoading}
        autoHeight={true}
      />
    </>
  );
}

function OptionsButton({
  index,
  facilityId,
  facilityName,
  ehridentifier,
  alternateEhridentifier,
  ehrType,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FormControlLabel
        sx={{ marginLeft: "0px" }}
        control={
          <IconButton
            aria-label="EHR Options"
            color="warning"
            onClick={handleClick}
            disableRipple>
            <span className="material-icons">more_vert</span>
          </IconButton>
        }
      />
      <Menu
        id={"edit-menu" + index}
        MenuListProps={{
          "aria-labelledby": "menu-button-" + index,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem disableRipple>
          {ehrType === 1 ? (
            <Link
              to={`./pccImport?facilityId=${facilityId}&ehridentifier=${ehridentifier}&alternateEhridentifier=${alternateEhridentifier}&facilityName=${facilityName}`}
              style={{ textDecoration: "none", color: "black" }}>
              PCC Import
            </Link>
          ) : ehrType === 2 ? (
            <Link
              to={`./alisImport?facilityId=${facilityId}&ehridentifier=${ehridentifier}&facilityName=${facilityName}`}
              style={{ textDecoration: "none", color: "black" }}>
              ALIS Import
            </Link>
          ) : ehrType === 3 ? (
            <Link
              to={`./matrixImport?facilityId=${facilityId}&ehridentifier=${ehridentifier}&facilityName=${facilityName}`}
              style={{ textDecoration: "none", color: "black" }}>
              Matrix Import
            </Link>
          ) : undefined}
        </MenuItem>
      </Menu>
    </>
  );
}
