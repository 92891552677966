import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";

import { useNavigate, useSearchParams } from "react-router-dom";

import DiningForm from "./forms/Dining";
import ResidentInformationForm from "./forms/ResidentInformation";
import DietForm from "./forms/Diet";
import MetricsForm from "./forms/Metrics";

import {
  useAddResidentMutation,
  useUpdateResidentMutation,
  useResidentDietSelectQuery,
  useResidentDiningareaSelectQuery,
  useGetResidentDetailQuery,
} from "../../../store/apis/ResidentApis";
import { useSelector } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import BaseSubmitButton from "../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../admin/admin.overlays";
import BaseContent from "../../shared/baseContent/BaseContent";
import { useResidentThickenedLiquidSelectQuery } from "../../../store/apis/GenericApis";
import { BaseButton } from "components/shared/BaseButton";
import { isEqual } from "lodash";

export default function AddResident() {
  const [searchParams] = useSearchParams();
  const currentResidentId = searchParams.get("id");
  const step = parseInt(searchParams.get("step") ?? 0);

  const [activeStep, setActiveStep] = useState(step);
  const [allowEHREdit, setAllowEHREdit] = useState(true);
  const [decliningBalanceEnabled, setDecliningBalanceEnabled] = useState(false);
  const facilityId = useSelector(selectFacilityId);
  const isAuthenticated = useIsAuthenticated();

  const {
    data: currentResident,
    isSuccess: residentSuccess,
    isError: residentError,
    isFetching: residentFetching,
  } = useGetResidentDetailQuery(
    {
      residentId: currentResidentId,
      facilityId: facilityId,
    },
    {
      skip: !facilityId || !currentResidentId || !isAuthenticated,
    }
  );

  const isEdit = !!currentResidentId;

  const [
    addResident,
    {
      isError: addError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addErrorMsg,
    },
  ] = useAddResidentMutation();
  const [
    updateResident,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateErrorMsg,
    },
  ] = useUpdateResidentMutation();

  const isError = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const error = addErrorMsg || updateErrorMsg;

  const { data: diet } = useResidentDietSelectQuery(
    {
      facilityId: facilityId,
      isTexture: false,
    },
    {
      skip: !facilityId,
    }
  );

  const { data: dietTexture } = useResidentDietSelectQuery(
    {
      facilityId: facilityId,
      isTexture: true,
    },
    {
      skip: !facilityId,
    }
  );

  const { data: liquidConsistency } = useResidentThickenedLiquidSelectQuery();

  const { data: diningmeal } = useResidentDiningareaSelectQuery({
    facilityId: facilityId,
  });

  useEffect(() => {
    if (residentSuccess && !residentError && currentResident) {
      reset(currentResident.resident);
      setValue(
        "heightInches",
        isEqual(currentResident.resident.heightInches, null)
          ? 0
          : currentResident.resident.heightInches
      );
      setAllowEHREdit(currentResident.canEdit);
      setDecliningBalanceEnabled(currentResident.decliningBalanceEnabled);
    }
  }, [currentResident]);

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    reset,
    trigger,
    setValue,
    getValues,
    register,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/MealCard/resident/details?id=${currentResidentId}`);
    }
  }, [isSuccess]);

  const onSubmit = async (data) => {
    data = assignEmptyIfNull(data, [
      "roomNumber",
      "nameTitle",
      "physician",
      "building",
      "wing",
      "bed",
      "ahtpatientId",
      "residentIdNumber",
      "nickname",
      "breakfastTable",
      "lunchTable",
      "supperTable",
    ]);

    if (!isEdit) {
      addResident({
        ...data,
        facilityId: facilityId,
        // id: 0,
        // residentName: `${data.lastName}, ${data.firstName}`,
        // userUpdated: `${data.firstName} ${data.lastName}`,
        tableSideBalance: 0,
        archive: false,
      });
    } else {
      updateResident({
        ...data,
        facilityId: facilityId,
        id: currentResidentId,
        heightInches: isEqual(data.heightInches, "") ? "0" : data.heightInches,
      });
    }
  };

  const isEditLoading = isEdit && residentFetching;

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) navigate(-1);
    else setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ResidentInformationForm
            errors={errors}
            control={control}
            isEdit={isEdit}
            register={register}
            canEdit={allowEHREdit}
          />
        );
      case 1:
        return (
          <MetricsForm
            errors={errors}
            control={control}
            isEdit={isEdit}
            register={register}
            canEdit={allowEHREdit}
          />
        );
      case 2:
        return (
          <DietForm
            diet={diet || []}
            dietTexture={dietTexture || []}
            liquidConsistency={liquidConsistency || []}
            errors={errors}
            control={control}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
          />
        );
      case 3:
        return (
          <DiningForm
            diningmeal={diningmeal}
            errors={errors}
            control={control}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            decliningBalanceEnabled={decliningBalanceEnabled}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const steps = ["Resident", "Metrics", "Diet", "Dining"];

  const CustomStepIcon = (props) => {
    const { active, completed } = props;
    const stepIcons = {
      1: <span className="material-icons">person</span>,
      2: <span className="material-icons">analytics</span>,
      3: <span className="material-icons">summarize</span>,
      4: <span className="material-icons">restaurant</span>,
    };

    return (
      <div
        className={
          active
            ? "icon-tab-style-active"
            : completed
            ? "icon-tab-style-active"
            : "icon-tab-style"
        }>
        {stepIcons[String(props.icon)]}
      </div>
    );
  };

  function assignEmptyIfNull(data, propertyNames) {
    propertyNames.map((res) => {
      if (data[res] === null) {
        data[res] = "";
      }
    });
    return data;
  }

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <BaseContent
          backLink={`/MealCard/resident/details?id=${currentResidentId}`}
          headerText={`${currentResident?.resident?.lastName}, ${
            currentResident?.resident?.firstName
          }:  ${
            activeStep == 0
              ? `Resident`
              : activeStep == 1
              ? "Metrics"
              : activeStep == 2
              ? "Diet"
              : "Dining"
          } `}
          disableHeight={true}>
          <Box height={{ md: "82vh", xl: "86vh" }}>
            {!allowEHREdit ? (
              <Typography
                variant="h6"
                sx={{
                  bgcolor: "warning.main",
                  p: "1px 20px",
                  border: "1px solid grey",
                  borderRadius: "5px",
                }}>
                Some fields on this page are disabled, because they are managed
                via an external EMR system
              </Typography>
            ) : undefined}
            <Box
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}>
              <Stepper
                activeStep={activeStep}
                className="icons-Tabs"
                style={{ width: "50%" }}>
                {steps?.map((label, index) => {
                  return (
                    <Step key={label + index}>
                      <StepLabel StepIconComponent={CustomStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            {activeStep === steps.length ? (
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            ) : (
              <FormProvider>
                <form>
                  {getStepContent()}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    paddingY={{ md: 3, xl: 0 }}>
                    <Box sx={{ display: "flex", flexDirection: "flex-start" }}>
                      <BaseButton
                        text={"Back"}
                        startIcon={"keyboard_arrow_left"}
                        onClick={handleBack}
                      />

                      {activeStep < steps.length - 1 ? (
                        <BaseButton
                          text={"Next"}
                          endIcon={"keyboard_arrow_right"}
                          onClick={handleNext}
                        />
                      ) : undefined}
                    </Box>
                    <BaseSubmitButton
                      onClick={handleSubmit(onSubmit)}
                      isSubmitting={isSubmitting}
                      text={"Submit"}
                    />
                  </Box>
                  {isError && (
                    <Box>
                      <Typography color={"error"}>
                        There was an error submitting the form, please try
                        again.
                      </Typography>
                      <Typography color={"error"}>
                        {error?.data?.messages}
                      </Typography>
                    </Box>
                  )}
                </form>
              </FormProvider>
            )}
          </Box>
        </BaseContent>
      )}
    </>
  );
}
