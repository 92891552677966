import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ColumnWithData } from "./ColumnWithData";
import { useGetMenuRecipesQuery } from "../../../../../store/apis/MenuManagerApi";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { isNil } from "lodash";
import { setIsChangeTrue } from "store/slices/menuPlannerSlice/LeaveNavigation";
import { useDispatch } from "react-redux";

export const RecipeBoard = ({
  weekNumber,
  isDescription,
  isCost,
  menuId,
  menuPlannerData,
  setMenuPlannerData,
}) => {
  // const [setColumnHeading] = useState([]);
  const [backroundColor, setBackroundColor] = useState([]);
  const dispatch = useDispatch();

  const { data: RecipesData, isFetching: loading } = useGetMenuRecipesQuery(
    {
      menuId: menuId,
      weekNumber,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !weekNumber || weekNumber === "", // Skip the initial requests without menuId and weekNumber
    }
  );
  useEffect(() => {
    setMenuPlannerData(RecipesData);
    // console.log(weekNumber);
  }, [RecipesData]);
  const swapLunchAndSupper = (index) => {
    dispatch(setIsChangeTrue(true));
    const menuPlanner = structuredClone(menuPlannerData);
    const lunchData = menuPlannerData?.resultSet
      .flatMap((data, index) => ({ data, index }))
      .filter((item) => item.data.mealName === "Lunch")
      .map((filteredItem) => {
        const { data, index } = filteredItem; // Extract the data and index
        // Process each filtered data object here if needed
        return { ...data, originalIndex: index }; // Add the original index to the object
      });
    const supperData = menuPlannerData?.resultSet
      .flatMap((data, index) => ({ data, index }))
      .filter((item) => item.data.mealName === "Supper")
      .map((filteredItem) => {
        const { data, index } = filteredItem; // Extract the data and index
        // Process each filtered data object here if needed
        return { ...data, originalIndex: index }; // Add the original index to the object
      });
    let lunchRecipes = lunchData[0]?.days[index].recipes;
    let supperRecipes = supperData[0]?.days[index].recipes;
    menuPlanner.resultSet[lunchData[0]?.originalIndex].days[index].recipes =
      supperRecipes;
    menuPlanner.resultSet[supperData[0]?.originalIndex].days[index].recipes =
      lunchRecipes;
    setMenuPlannerData(menuPlanner);
  };

  return (
    <>
      {loading ||
      !menuPlannerData ||
      !menuPlannerData?.resultSet ||
      !menuPlannerData?.resultSet[0]?.columnHeadings ? (
        <EditSkeleton />
      ) : (
        <Box width={{ md: "100%", xl: "100%" }} overflow="auto">
          <Box
            display="flex"
            flexDirection="row"
            height={{ md: "6vh", xl: "5vh" }}>
            {menuPlannerData?.resultSet[0]?.columnHeadings?.map(
              (days, index) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  key={index}
                  width={{ md: "100%", xl: "100%" }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      component="h1"
                      key={index}>
                      {days}
                    </Typography>
                    <span
                      style={{
                        display: "flex",
                        marginLeft: "2px",
                        color: "var(--tealColor)",
                        cursor: "pointer",
                      }}
                      onClick={() => swapLunchAndSupper(index)}
                      className="material-icons">
                      swap_vert
                    </span>
                  </Box>
                  <Box>
                    {isCost ? (
                      <Typography
                        variant="h6"
                        component="h1"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "12px",
                        }}
                        key={index}>
                        Cost: $
                        {menuPlannerData?.dailyCost[index]?.cost.toFixed(2)}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              )
            )}
          </Box>
          <Box height={{ md: "68vh", xl: "76vh" }} overflow={"scroll"}>
            {!isNil(menuPlannerData) &&
              menuPlannerData?.resultSet?.map((data, i) => (
                <Box
                  marginBottom="10px"
                  key={i}
                  display="flex"
                  flexDirection="row">
                  <Box>
                    <Typography
                      variant="subtitle1"
                      component="h6"
                      fontWeight="bold"
                      sx={{ writingMode: "vertical-rl" }}>
                      {data?.mealName === "Supper" ? "Dinner" : data?.mealName}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    width={{ md: "100%", xl: "100%" }}>
                    {menuPlannerData?.resultSet[0]?.columnHeadings?.map(
                      (item, index) => (
                        <ColumnWithData
                          recipeArr={
                            menuPlannerData?.resultSet[i].days[index] || []
                          }
                          rows={i}
                          column={index}
                          setMenuPlannerData={setMenuPlannerData}
                          menuPlannerData={menuPlannerData}
                          isDescription={isDescription}
                          isCost={isCost}
                          mealName={data?.mealName}
                          day={item}
                          key={index}
                          backroundColor={backroundColor}
                          setBackroundColor={setBackroundColor}
                          isOtherMenu={weekNumber !== -1}
                        />
                      )
                    )}
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </>
  );
};
